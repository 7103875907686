import { motion } from "framer-motion";
import React from "react";

type SkillsType = {
    id:number;
    name:string
}


const Skills: React.FC<SkillsType> = ({id, name}): JSX.Element => {

    const [isActive, setIsActive] = React.useState(false)

    return (
      <motion.div
      className="skill-card"
      onClick={() => setIsActive(!isActive)}
      animate={{
        rotate: isActive ? 1 : 0
      }}
      initial={{
        opacity: 0,
        // if odd index card,slide from right instead of left
        x: id % 2 === 0 ? 50 : -50
      }}
      whileInView={{
        opacity: 1,
        x: 0, // Slide in to its original position
        transition: {
          duration: 1 // Animation duration
        }
      }}
      viewport={{ once: true }}
    >
    
      <div className="skill-details">
        <h2 className="text-2xl font-extralight ">{name}</h2>
      </div>
      </motion.div>
    );
};

export default Skills;