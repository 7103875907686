import React, { useEffect } from "react";
import { motion, useScroll } from "framer-motion";
import ProjectCard from "./components/ProjectCard";
import { projects } from "./api/data";
import AboutMe from "./components/AboutMe";
import Skills from "./components/Skills";
import { skills } from "./api/data";
import ContactForm from "./components/ContactForm";

const App: React.FC = () => {
  const { scrollYProgress } = useScroll();
  useEffect(() => {
    document.title = "Mubarig's Portfolio Site";
  });


  return (
    <>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
      <div className="mt-4 grid relative w-full px-8 container my-12 mx-auto  md:px-12">
        <AboutMe />
        <div className="w-30">
          <ContactForm />
        </div>
        <div className="skill">
          {skills.map((skills, index) => (
            <Skills key={index} {...skills} />
          ))}
        </div>
        {/* <div className="projects">
          {projects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </div> */}
      </div>
    </>
  );
};

export default App;
