export const skills = [
  { id: 1, name: "ReactJS" },
  { id: 2, name: "Typescript" },
  { id: 3, name: "Javascript" },
  { id: 4, name: "Next.js" },
  { id: 5, name: "Remix" },
  { id: 6, name: "GraqhQL" },
  { id: 7, name: "TailwindCSS" },
  {id: 8, name: "Redux"},
  {id: 8, name: "Python"},
  {id: 8, name: "Java"},


  // {},{},{},
  // {},{},{},
];

export const projects = [
  {
    index: 1,
    title: "Project 1",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    imageUrl: "project1.jpg",
    githubUrl: "https://github.com/username/project1",
  },
  {
    index: 2,
    title: "Project 2",
    description:
      "Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.",
    imageUrl: "project2.jpg",
    githubUrl: "https://github.com/username/project2",
  },
];
