// ContactForm.tsx
import { motion, useCycle } from "framer-motion";
import React, { useState } from "react";

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [showForm, setShowForm] = useState(false);
  const [isActive, setIsActive] = React.useState(false)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setButtonLoading(true);
    const response = await fetch("https://formspree.io/f/<your-form-id>", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setButtonLoading(false);
    } else {
      alert("Failed to submit form");
      setButtonLoading(false);
    }
  };

  const toggleForm = () => {
    setShowForm((prevState) => !prevState);
  };

  return (
    <div className="mx-auto mb-10 text-1xl">
      <div className="flex justify-center">
        <button
          onClick={toggleForm}
          className="box arrow-bottom ml-10 text-white p-3 rounded-md w-full"
        >
          {showForm ? "Hide Form" : "Contact Me"}
        </button>
      </div>

      {showForm && (
            <motion.div
            className="contact-form"
            onClick={() => setIsActive(!isActive)}
            animate={{
              rotate: isActive ? 1 : 0
            }}
            initial={{
              opacity: 0,
              // if odd index card,slide from right instead of left
              x: 2 % 2 === 0 ? 50 : -50
            }}
            whileInView={{
              opacity: 1,
              x: 0, // Slide in to its original position
              transition: {
                duration: 1 // Animation duration
              }
            }}
            viewport={{ once: true }}
          >
        <form onSubmit={handleSubmit} className="space-y-6 contact-form">
          <div>
            <label htmlFor="name" className="block text-sm font-medium">
              Name
            </label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={handleChange}
              required
              className="mt-1 p-2 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="mt-1 p-2 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div>
            <label htmlFor="message" className="block text-sm font-medium">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              rows={4}
              value={formData.message}
              onChange={handleChange}
              required
              className="mt-1 p-2 block w-full border-gray-300 rounded-md"
            />
          </div>
          <div>
            <button
              type="submit"
              className="contact-form__button p-3 rounded-md w-full"
            >
              Submit
            </button>
          </div>
        </form>
        </motion.div>
      )}
    </div>
  );
};

export default ContactForm;
