import { motion } from "framer-motion";
import Me from "../assets/images/me-muba.jpg";
import ContactForm from "./ContactForm";
const AboutMe: React.FC = () => {
  const text = "Mubarig Cisman".split(" ");

  return (
    <div className="container my-8 mx-auto px-4 md:px-10">
      <div className="flex flex-wrap -mx-1 lg:-mx-4">
        {/* <!-- Column --> */}
        <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
          {/* <!-- Article --> */}
          <div className="max-h-30">
            <motion.div
              className="card-container"
              initial="offscreen"
              whileInView="onscreen"
              viewport={{ once: true, amount: 0.8 }}
            >
              <img
                className="bg-white rounded-full sm:h-auto  object-cover object-top"
                src={Me}
                alt="Me"
              />
            </motion.div>
          </div>
          {/* <!-- END Article --> */}
        </div>
        {/* <!-- END Column --> */}

        {/* <!-- Column --> */}
        <div className="my-1 px-1 w-full md:w-1/3 lg:my-3 lg:px-4 lg:w-1/2">
          {/* <!-- Article --> */}
          <article className="overflow-hidden">
            <h2 className="text-2xl font-bold mb-4">It is Me</h2>
            <h1 className="text-3xl uppercase  font-bold mb-4">
              {text.map((el, i) => (
                <motion.span
                  initial={{ opacity: 0.25 }}
                  animate={{ opacity: 0.95 }}
                  transition={{
                    duration: 0.25,
                    delay: i / 100,
                  }}
                  key={i}
                >
                  {el}{" "}
                </motion.span>
              ))}
            </h1>
            <p className="font-semibold mb-5">Frontend Engineer</p>
            <p>
              Meet MUBARIG, a passionate frontend engineer with a flair for
              creating captivating user experiences. With expertise spanning
              across a diverse array of technologies.
            </p>
            <motion.button whileTap="tap" whileHover="hover">
              <svg>
                <motion.path />
              </svg>
            </motion.button>
            {/* <header className="flex items-center justify-between leading-tight p-2 md:p-4">
              <h1 className="text-lg">
                <a className="no-underline hover:underline text-black" href="#">
                  Article Title
                </a>
              </h1>
              <p className="text-grey-darker text-sm">11/1/19</p>
            </header> */}
            {/* 
            <footer className="flex items-center justify-between leading-none p-2 md:p-4">
              <a
                className="flex items-center no-underline hover:underline text-black"
                href="#"
              >
                <img
                  alt="Placeholder"
                  className="block"
                  src="https://picsum.photos/32/32/?random"
                />
                <p className="ml-2 text-sm">Author Name</p>
              </a>
              <a
                className="no-underline text-grey-darker hover:text-red-dark"
                href="#"
              >
                <span className="hidden">Like</span>
                <i className="fa fa-heart"></i>
              </a>
            </footer> */}
          </article>
          {/* <!-- END Article --> */}
        </div>
        {/* <!-- END Column --> */}
      </div>
    </div>
  );
};

export default AboutMe;
